<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-12">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Filtrar productos"
            ></b-form-input>
            <b-table striped hover :items="products" :fields="fields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
              <template #cell(actions)="data">
                <b-button size="sm" class="mb-2" variant="success" :id="'edit' + data.item.id" @click="$router.push({name: 'product_edit', params:{id: data.item.id}})">
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </b-button>
                <b-tooltip :target="'edit' + data.item.id" triggers="hover">
                  Editar producto
                </b-tooltip>
              </template>
            </b-table>
          </div>
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreateProduct",
  components: {},
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      products: [],
      fields: [
        {
          key: "name",
          label: "Producto"
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "actions",
          label: "Acciones"
        }
      ]
    };
  },
  methods: {
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getProducts() {
      ApiService.get("product").then(res => {
        this.products = res.data;
        this.totalRows = this.products.length;
      });
    }
  },
  mounted() {
    this.getProducts();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Producto" },
      { title: "Lista de productos" }
    ]);
  }
};
</script>
